<template>
  <div>
    <el-card class="box-card">
      <h2>Echarts报告</h2>
      <div ref="chart" style="width: auto;height:400px"></div>
    </el-card>

    <el-card class="box-card">
      <div slot="header">
        debug
      </div>
      <div style="height: 500px;">
        <vue-monaco v-model="jsonCode"
        @change="handleBlur"
        style="height: 300px;"
        language="python"
        theme="vs"
        automaticLayout: true
        ></vue-monaco>
      </div>
    </el-card>

  </div>
</template>

<script>
// import echarts from 'echarts'
import * as echarts from 'echarts'
import { optionData } from '@/views/chart_report/option_data.js'
export default {
  name: 'ChartReport',
  data () {
    return {
      qe: '',
      jsonCode: '{ "key": "value", "array": [1, 2, 3], "nested": { "nestedKey": "nestedValue" } }'
    }
  },
  created () {},
  mounted () {
    this.echartsReport()
  },
  methods: {
    handleBlur () {
      // 在这里执行你想要的代码
      console.log('vue-monaco失去焦点')
      // 清除之前的定时器
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }

      // 设置新的定时器，在3秒后执行特定的代码
      this.timeoutId = setTimeout(() => {
        console.log('Content has not changed in the last 3 seconds');
        // 在这里执行你的特定代码
      }, 3000);
    },
    echartsReport () {
      const chart = this.$refs.chart
      // var myChart = echarts.init(document.getElementById('chart'), 'light')
      if (chart) {
        const myChart = echarts.init(chart, 'default')
        const option = optionData
        myChart.setOption(option)
        window.addEventListener('resize', function () {
          myChart.resize()
        })
      }
    }
  }
}
</script>

<style>

</style>
